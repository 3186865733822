<template>
  <div class="card">
    <p class="title">基本信息</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <!-- <van-field
        v-model.number="form.EastWestLength"
        input-align="right"
        label="东西长度（CM）"
        type="number"
        :rules="[{ required: true, message: '请填写东西长度' }]"
      />
      <van-field
        v-model.number="form.NorthSouthWidth"
        input-align="right"
        label="南北宽度（CM）"
        type="number"
        :rules="[{ required: true, message: '请填写南北宽度' }]"
      /> -->
      <!-- @input="oninput" -->
      <van-field
        @keydown="handleInput"
        v-model.number="form.houseHeight"
        input-align="right"
        label="房屋高度（m）"
        type="number"
        :rules="[{limit: 2, required: true, message: '请填写房屋高度' }]"
      />
      <div class="item">
        <van-field label="房屋朝向" input-align="right" readonly />
        <span @click.stop="houseOrientationTypeShow = true"
        >{{ form.facingDirection }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options" v-show="houseOrientationTypeShow">
          <li @click="chooseHouseOrientationType('南偏东')">南偏东</li>
          <li @click="chooseHouseOrientationType('正南')">正南</li>
          <li @click="chooseHouseOrientationType('南偏西')">南偏西</li>
        </ul>
      </div>
      <van-field
        v-if="form.facingDirection !== '正南'"
        v-model.number="form.facingDegree"
        input-align="right"
        type="number"
        label="偏向度数(°)"
        :rules="[{ required: true, message: '请填写偏向度数' }]"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.gridConnectionDistance"
        input-align="right"
        label="距并网点距离（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距并网点距离' }]"
      />
      <div class="item">
        <van-field label="屋面类型" input-align="right" readonly />
        <span @click.stop="roofTypeShow = true"
        >{{ form.roofingType }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="roofTypeShow">
          <li @click="chooseRoofTypeShow('预制板')">预制板</li>
          <li @click="chooseRoofTypeShow('现浇板')">现浇板</li>
        </ul>
      </div>
      <van-field
        @keydown="handleInput"
        v-model.number="form.roofSlabThickness"
        input-align="right"
        type="number"
        label="屋面厚度（m）"
        :rules="[{ required: true, message: '请填写屋面厚度' }]"
      />
      <div class="item">
        <van-field label="安装方式" input-align="right" readonly />
        <span class="span" @click.stop="installationModeTypeShow = true"
        >{{ form.installationMethod }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options widthOptions" v-show="installationModeTypeShow">
          <li v-show="Pa" @click="chooseInstallationMode('混泥土基墩')">混泥土基墩</li>
          <li @click="chooseInstallationMode('膨胀螺栓')">膨胀螺栓</li>
          <!-- 锦浪没有混凝土+膨胀螺栓 -->
          <li v-show="Pa" @click="chooseInstallationMode('混凝土+膨胀螺栓')">
            混凝土+膨胀螺栓
          </li>
        </ul>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <van-button native-type="submit" class="determine"> 确定 </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
// import emitter from '../../plugins/eventBus'
// import { FlatRoofInfo } from '@/api/api'
export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    const data = reactive({
      form: {
        // EastWestLength: '',
        // NorthSouthWidth: '',
        EastWestLength: sessionStorage.getItem('inpValW'),
        NorthSouthWidth: sessionStorage.getItem('inpValH'),
        houseHeight: '', // 房屋高度
        facingDirection: "南偏东", // 房屋朝向 1.南偏东。2南偏西。3正南。
        facingDegree: '', // 偏向度数
        gridConnectionDistance: '', // 距并网点距离
        roofSlabThickness: '', // 屋面厚度
        installationMethod: "混泥土基墩", // 安装方式 1：混泥土支墩  2：膨胀螺栓  3：混凝土+膨胀螺栓
        roofingType: "预制板", // 屋面类型 /1：现浇板  2：预制板  3：木望板  4：凛橼结构
        TopViewPic: "", // 俯视图
        RearViewPic: "", // 后视图
        TopViewJson: "", // 俯视图Json
        RearViewJson: "", // 后视图Json
      },
      houseOrientationTypeShow: false,
      roofTypeShow: false,
      installationModeTypeShow: false,
      Pa: true
    })

    onMounted(() => {
      const qing = JSON.parse(sessionStorage.getItem('FarmerInfo'))
      if (qing !== null && qing.wind_pressure !== '低风压') {
        data.pa = false
      }
      console.log(qing, '---------------------------------------')
      window.addEventListener('click', methodsMap.handleBodyClick)
      console.log(JSON.parse(sessionStorage.getItem('flatAgain')), '平屋顶基础数据')
      if (sessionStorage.getItem('baseInfo') !== null) {
        var databas = JSON.parse(sessionStorage.getItem('baseInfo'))
        data.form.houseHeight = databas.houseHeight
        data.form.facingDegree = databas.facingDegree
        data.form.facingDirection = databas.facingDirection
        if (databas.facingDirection === 1) {
          data.form.facingDirection = "南偏东"
        } else if (databas.facingDirection === 2) {
          data.form.facingDirection = "南偏西"
        } else if (databas.facingDirection === 3) {
          data.form.facingDirection = "正南"
        }
        data.form.gridConnectionDistance = databas.gridConnectionDistance
        data.form.installationMethod = databas.installationMethod
        if (databas.installationMethod === 1) {
          data.form.installationMethod = "混泥土基墩"
        } else if (databas.installationMethod === 2) {
          data.form.installationMethod = "膨胀螺栓"
        } else if (databas.installationMethod === 3) {
          data.form.installationMethod = "混凝土+膨胀螺栓"
        }
        data.form.roofSlabThickness = databas.roofSlabThickness
        data.form.roofingType = databas.roofingType
        if (databas.roofingType === 1) {
          data.form.roofingType = "现浇板"
        } else if (databas.roofingType === 2) {
          data.form.roofingType = "预制板"
        }
      } else if (JSON.parse(sessionStorage.getItem('flatAgain'))) {
        var dataFlat = JSON.parse(sessionStorage.getItem('flatAgain'))
        if (dataFlat.flatRoofBasicModel !== null) {
          var dataInfo = dataFlat.flatRoofBasicModel / 100
          data.form.houseHeight = dataInfo.houseHeight / 1000
          if (dataInfo.facingDirection === 1) {
            data.form.facingDirection = "南偏东"
          } else if (dataInfo.facingDirection === 2) {
            data.form.facingDirection = "南偏西"
          } else if (dataInfo.facingDirection === 3) {
            data.form.facingDirection = "正南"
          }
          data.form.facingDegree = dataInfo.facingDegree
          data.form.gridConnectionDistance = dataInfo.gridConnectionDistance / 1000
          if (dataInfo.roofingType === 1) {
            data.form.roofingType = "现浇板"
          } else if (dataInfo.roofingType === 2) {
            data.form.roofingType = "预制板"
          }
          data.form.roofSlabThickness = dataInfo.roofSlabThickness / 1000
          if (dataInfo.installationMethod === 1) {
            data.form.installationMethod = "混泥土基墩"
          } else if (dataInfo.installationMethod === 2) {
            data.form.installationMethod = "膨胀螺栓"
          } else if (dataInfo.installationMethod === 3) {
            data.form.installationMethod = "混凝土+膨胀螺栓"
          }
        }
      }
    })
    const methodsMap = {
      // oninput(e) {
      //   // 通过正则过滤小数点后两位
      //   // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
      //   // e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      //   // e.target.value = e.target.value.replace(/^([1-9]+[\d]*(.[0-9]{1,2})?)$/)
      //   // e.target.value = e.target.value.replace(/^\d*(\.?\d{0,2})/g[0])
      // },
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },

      // keyInput(e) { // 过滤input密码类型只输入数字
      //   console.log('e', e)
      //   const val = e;
      //   if (val.includes(".")) {
      //     const numDian = val.toString().split(".")[1].length
      //     if (numDian === 2) {
      //       this.moneyMaxLeng = val.length
      //     } else {
      //       this.moneyMaxLeng = 140
      //     }
      //     console.log("numDian", numDian);
      //   }
      //   console.log('val', val)
      // },
      handleBodyClick() {
        data.houseOrientationTypeShow = false
        data.roofTypeShow = false
        data.installationModeTypeShow = false
      },
      chooseHouseOrientationType(item) {
        data.form.facingDirection = item
        data.houseOrientationTypeShow = false
      },
      chooseRoofTypeShow(item) {
        data.form.roofingType = item
        data.roofTypeShow = false
      },
      chooseInstallationMode(item) {
        data.form.installationMethod = item
        data.installationModeTypeShow = false
      },
      onSubmit() {
        // 存储基本信息
        // 存入数据库厘米转毫米
        const copyForm = JSON.parse(JSON.stringify(data.form))
        copyForm.eastWestLength = sessionStorage.getItem('inpValW') * 1000
        copyForm.northSouthWidth = sessionStorage.getItem('inpValH') * 1000
        copyForm.houseHeight = copyForm.houseHeight * 10 * 100
        copyForm.gridConnectionDistance = copyForm.gridConnectionDistance * 1000
        copyForm.roofSlabThickness = copyForm.roofSlabThickness * 1000
        if (copyForm.facingDirection === '南偏东') {
          copyForm.facingDirection = 1
        } else if (copyForm.facingDirection === '南偏西') {
          copyForm.facingDirection = 2
        } else {
          copyForm.facingDirection = 3
          data.form.facingDegree = 0
          copyForm.facingDegree = 0
        }
        if (copyForm.installationMethod === '混泥土基墩') {
          copyForm.installationMethod = 1
        } else if (copyForm.installationMethod === '膨胀螺栓') {
          copyForm.installationMethod = 2
        } else {
          copyForm.installationMethod = 3
        }
        if (copyForm.roofingType === '现浇板') {
          copyForm.roofingType = 1
        } else {
          copyForm.roofingType = 2
        }
        sessionStorage.removeItem('baseInfo')
        sessionStorage.setItem('baseInfo', JSON.stringify(data.form))
        sessionStorage.setItem('requestFlatRoof', JSON.stringify(copyForm))
        emit('popupClose', '基本信息')
      },
      cancelEvent() {
        emit('popupClose')
      }

    }

    return {
      ...toRefs(data),
      ...methodsMap
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 404px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 700;
      color: #323233;
      width: 80px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 144px;
    }
    .options {
      z-index: 1;
      width: 80px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 700;
      color: #1A1A1A;
      li {
        line-height: 20px;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .widthOptions {
      width: 144px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
