<template>
  <div>
    <!-- 锦浪tab -->
    <!-- eslint-disable vue/valid-v-model -->
    <div v-if="customer==='jinlang'">
      <van-tabs
        v-model:active="activeName"
        background="rgba(0,0,0,0)"
        title-inactive-color="#696C74"
        line-height="4px"
        line-width="50%"
        color="#04CC66"
        title-active-color="#04CC66"
        @click-tab="clickTab"
      >
        <van-tab title="屋顶一" name="a">
          <div class="com">
            <FlatRoof v-if="roofCom1 == '平屋顶'" />
            <SlopingRoof v-if="roofCom1 == '斜屋顶'" />
            <InnerbuildingRoof v-if="roofCom1 == '内厦屋顶'" />
            <OuterbuildingPlatformRoof
              v-if="roofCom1 == '外厦带平台屋顶'"
            />
          </div>
        </van-tab>
        <van-tab title="屋顶二" name="b">
          <div class="com">
            <FlatRoof v-if="roofCom2 == '平屋顶'" />
            <SlopingRoof v-if="roofCom2 == '斜屋顶'" />
            <InnerbuildingRoof v-if="roofCom2 == '内厦屋顶'" />
            <OuterbuildingPlatformRoof
              v-if="roofCom2 == '外厦带平台屋顶'"
            />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 晴天tab -->
    <div class="tabs" v-if="customer==='qingtian'">
      <span
        :class="{ spanact: activeName == 'a' }"
        @click="
          activeName = 'a';
          show = true
        "
        class="span1"
      >屋顶一</span
      >
      <span
        :class="{ spanact: activeName == 'b' }"
        @click="
          activeName = 'b';
          show = true
        "
        class="span2"
      >屋顶二</span
      >
    </div>
    <div
      class="com1"
      v-if="activeName == 'a' &&customer==='qingtian'"
    >
      <FlatRoof v-if="roofCom1 == '平屋顶'" />
      <SlopingRoof v-if="roofCom1 == '斜屋顶'" />
      <InnerbuildingRoof v-if="roofCom1 == '内厦屋顶'" />
      <OuterbuildingPlatformRoof v-if="roofCom1 == '外厦带平台屋顶'" />
    </div>
    <div
      class="com1"
      v-if="activeName == 'b' && customer==='qingtian'"
    >
      <FlatRoof v-if="roofCom2 == '平屋顶'" />
      <SlopingRoof v-if="roofCom2 == '斜屋顶'" />
      <InnerbuildingRoof v-if="roofCom2 == '内厦屋顶'" />
      <OuterbuildingPlatformRoof v-if="roofCom2 == '外厦带平台屋顶'" />
    </div>
    <div class="previewBtn" @click="previewEvent">预览</div>
    <van-popup v-model:show="show">
      <div class="roofone" v-if="activeName == 'a'">
        <p class="title">屋顶一</p>
        <van-form
          @submit="onSubmit"
          :submit-on-enter="false"
          validate-trigger="onSubmit"
          scroll-to-error
          error-message-align="right"
        >
          <div class="item">
            <van-field
              :border="false"
              label="屋顶类型"
              input-align="right"
              readonly
            />
            <span class="span" @click.stop="RoofType1Show = true"
            >{{ RoofType1 }}
              <img src="./../../assets//images/下拉1.png" alt="" />
            </span>
            <ul class="options" v-show="RoofType1Show">
              <li @click="chooseRoofType1('平屋顶')">平屋顶</li>
              <li @click="chooseRoofType1('斜屋顶')">斜屋顶</li>
              <li @click="chooseRoofType1('内厦屋顶')">内厦屋顶</li>
              <li @click="chooseRoofType1('外厦带平台屋顶')">外厦带平台屋顶</li>
            </ul>
          </div>
          <div class="btnbox">
            <div class="cancel" @click="cancelEvent">取消</div>
            <van-button native-type="submit" class="determine">
              确定
            </van-button>
          </div>
        </van-form>
      </div>
      <div class="rooftwo" v-if="activeName == 'b'">
        <p class="title">屋顶二</p>
        <van-form
          @submit="onSubmit"
          :submit-on-enter="false"
          validate-trigger="onSubmit"
          scroll-to-error
          error-message-align="right"
        >
          <div class="item">
            <van-field label="屋顶类型" input-align="right" readonly />
            <span class="span" @click.stop="RoofType2Show = true"
            >{{ RoofType2 }}
              <img src="./../../assets//images/下拉1.png" alt="" />
            </span>
            <ul class="options" v-show="RoofType2Show">
              <li @click="chooseRoofType2('平屋顶')">平屋顶</li>
              <li @click="chooseRoofType2('斜屋顶')">斜屋顶</li>
              <li @click="chooseRoofType2('内厦屋顶')">内厦屋顶</li>
              <li @click="chooseRoofType2('外厦带平台屋顶')">外厦带平台屋顶</li>
            </ul>
          </div>
          <van-field
            v-model.number="DistanceRoofX"
            input-align="right"
            label="距屋顶一西南角X（CM）"
            type="number"
            :rules="[{ required: true, message: '请填写距屋顶一西南角X' }]"
          />
          <van-field
            v-model.number="DistanceRoofY"
            input-align="right"
            label="距屋顶一西南角Y（CM）"
            type="number"
            :rules="[{ required: true, message: '请填写距屋顶一西南角Y' }]"
          />
          <div class="btnbox">
            <div class="cancel" @click="cancelEvent">取消</div>
            <van-button native-type="submit" class="determine">
              确定
            </van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
import FlatRoof from './components/FlatRoof'
import SlopingRoof from './components/SlopingRoof'
import InnerbuildingRoof from './components/InnerbuildingRoof'
import OuterbuildingPlatformRoof from './components/OuterbuildingPlatformRoof'
import { useRouter } from 'vue-router'

import {
  reactive,
  toRefs,
  onMounted
} from 'vue'
export default {
  components: {
    FlatRoof,
    SlopingRoof,
    InnerbuildingRoof,
    OuterbuildingPlatformRoof
  },
  setup() {
    const router = useRouter()
    const data = reactive({
      customer: window.customer,
      activeName: 'a',
      show: true,
      RoofType1: '平屋顶',
      RoofType2: '斜屋顶',
      roofCom1: '平屋顶',
      roofCom2: '斜屋顶',
      DistanceRoofX: 200,
      DistanceRoofY: 1000,
      RoofType1Show: false,
      RoofType2Show: false
    })
    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleBodyClick() {
        data.RoofType1Show = false
        data.RoofType2Show = false
      },
      chooseRoofType1(item) {
        data.RoofType1 = item
        data.RoofType1Show = false
      },
      chooseRoofType2(item) {
        data.RoofType2 = item
        data.RoofType2Show = false
      },
      previewEvent() {
        if (
          (data.activeName === 'a' && data.roofCom1 === '平屋顶') ||
        (data.activeName === 'b' && data.roofCom2 === '平屋顶')
        ) {
          router.push('/flatRoofPreview')
        }
        if (
          (data.activeName === 'a' && data.roofCom1 === '斜屋顶') ||
        (data.activeName === 'b' && data.roofCom2 === '斜屋顶')
        ) {
          router.push('/slopingRoofPreview')
        }
        if (
          (data.activeName === 'a' && data.roofCom1 === '内厦屋顶') ||
        (data.activeName === 'b' && data.roofCom2 === '内厦屋顶')
        ) {
          router.push('/innerbuildingPreview')
        }
        if (
          (data.activeName === 'a' && data.roofCom1 === '外厦带平台屋顶') ||
        (data.activeName === 'b' && data.roofCom2 === '外厦带平台屋顶')
        ) {
          router.push('/outerbuildingPreview')
        }
      },
      clickTab() {
        data.show = true
      },
      onSubmit() {
        data.show = false
        data.roofCom1 = data.RoofType1
        data.roofCom2 = data.RoofType2
      },
      cancelEvent() {
        data.show = false
      }
    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/style/jinlang/compositeRoof.scss';
@import '@/style/qingtian/compositeRoof.scss';
</style>
