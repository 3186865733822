<template>
  <div class="card">
    <p class="title">基本信息</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
<!--      <van-field-->
<!--        v-model.number="form.EastWestLength"-->
<!--        input-align="right"-->
<!--        label="东西长度（m）"-->
<!--        type="number"-->
<!--        :rules="[{ required: true, message: '请填写东西长度' }]"-->
<!--      />-->
<!--      <van-field-->
<!--        v-model.number="form.NorthSouthWidth"-->
<!--        input-align="right"-->
<!--        label="南北宽度（m）"-->
<!--        type="number"-->
<!--        :rules="[{ required: true, message: '请填写南北宽度' }]"-->
<!--      />-->
<!--      <van-field-->
<!--        v-model.number="form.SouthSlopeWidth"-->
<!--        input-align="right"-->
<!--        label="南坡宽度（m）"-->
<!--        type="number"-->
<!--        :rules="[{ required: true, message: '请填写南坡宽度' }]"-->
<!--      />-->
      <van-field
          v-model.number="form.NorthSlope"
          input-align="right"
          label="北坡坡度（°）"
          type="number"
          :rules="[{ required: true, message: '请填写北坡坡度' }]"
      />
      <van-field
        v-model.number="form.SouthSlope"
        input-align="right"
        label="南坡坡度（°）"
        type="number"
        :rules="[{ required: true, message: '请填写南坡坡度' }]"
      />
<!--      <van-field-->
<!--        v-model.number="form.NorthSlopeWidth"-->
<!--        input-align="right"-->
<!--        label="北坡宽度（m）"-->
<!--        type="number"-->
<!--        :rules="[{ required: true, message: '请填写北坡宽度' }]"-->
<!--      />-->
      <van-field
        v-model.number="form.EavesHigh"
        input-align="right"
        label="屋檐高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写屋檐高度' }]"
      />
<!--      <van-field-->
<!--        v-model.number="form.SouthOverhang"-->
<!--        input-align="right"-->
<!--        label="南侧挑檐宽度（CM）"-->
<!--        type="number"-->
<!--        :rules="[{ required: true, message: '请填写南侧挑檐宽度' }]"-->
<!--      />-->
<!--      <van-field-->
<!--        v-model.number="form.NorthOverhang"-->
<!--        input-align="right"-->
<!--        label="北侧挑檐宽度（CM）"-->
<!--        type="number"-->
<!--        :rules="[{ required: true, message: '请填写北侧挑檐宽度' }]"-->
<!--      />-->
      <van-field
        v-model.number="form.HouseHeight"
        input-align="right"
        label="房屋高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写房屋高度' }]"
      />
      <div class="item">
        <van-field label="房屋朝向" input-align="right" readonly />
        <span @click.stop="houseOrientationTypeShow = true"
        >{{ form.HouseFacing }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options" v-show="houseOrientationTypeShow">
          <li @click="chooseHouseOrientationType('南偏东')">南偏东</li>
          <li @click="chooseHouseOrientationType('正南')">正南</li>
          <li @click="chooseHouseOrientationType('南偏西')">南偏西</li>
        </ul>
      </div>
      <van-field
        v-if="form.HouseFacing !== '正南'"
        v-model="form.FacingDegree"
        input-align="right"
        label="偏向度数（°）"
        :rules="[{ required: true, message: '请填写偏向度数' }]"
      />
      <van-field
        v-model.number="form.GridConnectionDistance"
        input-align="right"
        label="距并网点距离（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距并网点距离' }]"
      />

<!--      <div class="item">-->
<!--        <van-field label="上人通道位置" input-align="right" readonly />-->
<!--        <span @click.stop="AccessModeTypeShow = true"-->
<!--        >{{ form.AccessPosition }}-->
<!--          <img src="./../../assets//images/下拉1.png" alt="" />-->
<!--        </span>-->
<!--        <ul class="options fourline" v-show="AccessModeTypeShow">-->
<!--          <li @click="choosePassageOrientationType('无')">无</li>-->
<!--          <li @click="choosePassageOrientationType('东侧')">东侧</li>-->
<!--          <li @click="choosePassageOrientationType('西侧')">西侧</li>-->
<!--          <li @click="choosePassageOrientationType('南侧')">南侧</li>-->
<!--          <li @click="choosePassageOrientationType('北侧')">北侧</li>-->
<!--        </ul>-->
<!--      </div>-->

<!--      <div class="item">-->
<!--        <van-field label="上人通道方式" input-align="right" readonly />-->
<!--        <span @click.stop="AccessPositionTypeShow = true"-->
<!--        >{{ form.AccessMode }}-->
<!--          <img src="./../../assets//images/下拉1.png" alt="" />-->
<!--        </span>-->
<!--        <ul class="options fourline" v-show="AccessPositionTypeShow">-->
<!--          <li @click="chooseAccessMode('无')">无</li>-->
<!--          <li @click="chooseAccessMode('爬梯')">爬梯</li>-->
<!--          <li @click="chooseAccessMode('楼梯')">楼梯</li>-->
<!--          <li @click="chooseAccessMode('孔洞')">孔洞</li>-->
<!--          <li @click="chooseAccessMode('其他')">其他</li>-->
<!--        </ul>-->
<!--      </div>-->

      <van-field
        v-model.number="form.RidgeHeight"
        input-align="right"
        label="屋脊高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写屋脊高度' }]"
      />
<!--      <div class="item">-->
<!--        <van-field label="是否有装饰物" input-align="right" readonly />-->
<!--        <span class="span2" @click.stop="decorationShow = true"-->
<!--        >{{ form.IsHaveDecorations }}-->
<!--          <img src="./../../assets//images/下拉1.png" alt="" />-->
<!--        </span>-->
<!--        <ul class="options options2" v-show="decorationShow">-->
<!--          <li @click="chooseDecoration('无')">无</li>-->
<!--          <li @click="chooseDecoration('有,不可拆除')">有, 不可拆除</li>-->
<!--          <li @click="chooseDecoration('有,可拆除')">有, 可拆除</li>-->
<!--        </ul>-->
<!--      </div>-->
      <div class="item">
        <van-field label="屋面类型" input-align="right" readonly />
        <span @click.stop="roofTypeShow = true"
        >{{ form.RoofingType }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options fourline" v-show="roofTypeShow">
          <li @click="chooseRoofTypeShow('现浇板')">现浇板</li>
          <li @click="chooseRoofTypeShow('预制板')">预制板</li>
          <li @click="chooseRoofTypeShow('木望板')">木望板</li>
          <li @click="chooseRoofTypeShow('檩橼结构')">檩橼结构</li>
        </ul>
      </div>
      <van-field
        v-if="form.RoofingType == '预制板' || form.RoofingType == '现浇板'"
        v-model.number="form.RoofSlabThickness"
        input-align="right"
        type="number"
        label="屋面厚度（m）"
        :rules="[{ required: true, message: '请填写屋面厚度' }]"
      />
      <!-- 木望板 -->
      <div v-if="form.RoofingType == '木望板'">
        <van-field
          v-model.number="form.WoodPurlinDiameter"
          input-align="right"
          label="木檩直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩直径' }]"
        />
        <van-field
          v-model.number="form.WoodPurlinSpacing"
          input-align="right"
          label="木檩间距（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩间距' }]"
        />
        <van-field
          v-model.number="form.WoodWatchboardThickness"
          input-align="right"
          label="木望板厚度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木望板厚度' }]"
        />
      </div>
      <!-- 檩橼结构 -->
      <div v-if="form.RoofingType == '檩橼结构'">
        <van-field
          v-model.number="form.PurlinDiameter"
          input-align="right"
          label="木檩条直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩条直径' }]"
        />
        <van-field
          v-model.number="form.PurlinSpacing"
          input-align="right"
          label="木檩条间距（m）"
          type="number"
          :rules="[{ required: true, message: '请填写木檩条间距' }]"
        />
        <van-field
          v-model.number="form.HerringboneBeamEastWest"
          input-align="right"
          label="人字梁东西跨度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写人字梁东西跨度' }]"
        />
        <van-field
          v-model.number="form.HerringboneBeamNorthSouth"
          input-align="right"
          label="人字梁南北跨度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写人字梁南北跨度' }]"
        />
        <van-field
          v-model.number="form.HerringboneGirderDiameter"
          input-align="right"
          label="人字梁主梁直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写人字梁主梁直径' }]"
        />
      </div>
      <div class="item">
        <van-field label="安装方式" input-align="right" readonly />
        <span class="widthSpan" @click.stop="installationModeTypeShow = true"
        >{{ form.InstallationMethod }}
          <img src="./../../assets//images/下拉1.png" alt="" />
        </span>
        <ul class="options widthOptions" v-show="installationModeTypeShow">
<!--          <li @click="chooseInstallationMode('混泥土基墩')">混泥土基墩</li>-->
<!--          <li @click="chooseInstallationMode('膨胀螺栓')">膨胀螺栓</li>-->
<!--          <li @click="chooseInstallationMode('混凝土+膨胀螺栓')">-->
<!--            混凝土+膨胀螺栓-->
<!--          </li>-->
          <li @click="chooseInstallationMode('前支后拉')">前支后拉</li>
          <li @click="chooseInstallationMode('挂钩')">挂钩</li>
        </ul>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <van-button native-type="submit" class="determine"> 确定 </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted, toRaw, watch } from 'vue'
export default {
  props: ['baseInfoShow'],
  emits: ['popupClose'],
  setup(props, { emit }) {
    // 监听基本信息组件的显示隐藏，在这来填充表单信息
    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    let form = reactive({
      roofType: 3, // 斜屋顶类型//1斜屋顶 2内厦 3 外厦
      EastWestLength: "", // 东西长度
      NorthSouthWidth: "", // 南北宽度
      SouthSlopeWidth: "", // 南坡宽度
      SouthSlope: "", // 南坡坡度
      NorthSlopeWidth: "", // 北坡宽度
      NorthSlope: "", // 北坡坡度
      EavesHigh: "", // 屋檐高度
      SouthOverhang: "", // 南侧挑檐宽度
      NorthOverhang: "", // 北侧挑檐宽度
      topViewPic: "",
      sideViewPic: "",
      rearViewPic: "",
      frontViewPic: "",
      HouseHeight: "", // 房屋高度
      HouseFacing: '正南', // 房屋朝向
      FacingDegree: "", // 偏向度数
      GridConnectionDistance: "", // 距并网点距离
      AccessPosition: '无', // 上人通道位置
      AccessMode: '无', // 上人通道方式
      RidgeHeight: "", // 屋脊高度
      IsHaveDecorations: '无', // 是否有装饰物
      RoofingType: '预制板', // 屋面类型
      RoofSlabThickness: "", // 屋面厚度
      WoodPurlinDiameter: "", // 木檩直径（CM）
      WoodPurlinSpacing: "", // 木檩间距（CM）
      WoodWatchboardThickness: "", // 木望板厚度（CM）
      PurlinDiameter: "", // 木檩条直径（CM）
      PurlinSpacing: "", // 木檩条间距（CM）
      HerringboneBeamEastWest: "", // 人字梁东西跨度（CM）
      HerringboneBeamNorthSouth: "", // 人字梁南北跨度（CM）
      HerringboneGirderDiameter: "", // 人字梁主梁直径（CM）
      InstallationMethod: '前支后拉', // 安装方式
    })
    sessionStorage.setItem('baseInfo', JSON.stringify(form))
    const houseOrientationTypeShow = ref(false)
    const passageLocationShow = ref(false)
    const roofTypeShow = ref(false)
    const installationModeTypeShow = ref(false)
    const AccessPositionTypeShow = ref(false)
    const AccessModeTypeShow = ref(false)
    const decorationShow = ref(false)


    watch(
        ()=> props.baseInfoShow, (newValue, oldValue)=> {
          const qing = JSON.parse(sessionStorage.getItem('projectAddRequest'))
          console.log(qing, '------------------')
          window.addEventListener('click', handleBodyClick)
          let innerBuildingAgain = null
          try {
            innerBuildingAgain = JSON.parse(sessionStorage.getItem('innerBuildingAgain')).obliqueRoofBasicModel
          } catch (e) {
            console.log(e)
          }
          let database = JSON.parse(sessionStorage.getItem('baseInfo'))
          if(database !== null && database.isEdit !== undefined ) {
            if(database.HouseHeight !=='' && database.HouseHeight !==0 && database.HouseHeight !==null && database.HouseHeight !== undefined) { // 房屋高度
              form.HouseHeight = database.HouseHeight / 10 / 100
            }else {
              form.HouseHeight = ''
            }

            if (database.HouseFacing === 1) { // 房屋朝向
              form.HouseFacing = '南偏东'
            } else if (database.HouseFacing === 2) {
              form.HouseFacing = '南偏西'
            } else if (database.HouseFacing === 3) {
              form.HouseFacing = '正南'
            }

            if (database.FacingDegree !== 0 && database.FacingDegree !== '' && database.FacingDegree !== null && database.FacingDegree !== undefined) { // 偏向度数
              form.FacingDegree = parseInt(database.FacingDegree)
            }else {
              form.FacingDegree = ''
            }

            if (database.GridConnectionDistance !==0 && database.GridConnectionDistance !== null && database.GridConnectionDistance !=='' && database.GridConnectionDistance !== undefined) { // 距并网点距离
              form.GridConnectionDistance = database.GridConnectionDistance / 10 / 100
            } else {
              form.GridConnectionDistance = ''
            }

            if (database.AccessPosition === 0) { // 上人通道位置
              form.AccessPosition = '无'
            } else if (database.AccessPosition === 1) {
              form.AccessPosition = '东侧'
            } else if (database.AccessPosition === 2) {
              form.AccessPosition = '西侧'
            } else if (database.AccessPosition === 3) {
              form.AccessPosition = '南侧'
            } else if (database.AccessPosition === 4) {
              form.AccessPosition = '北侧'
            }

            if (database.AccessMode === 0) { // 上人通道方式
              form.AccessMode = '无'
            } else if (database.AccessMode === 1) {
              form.AccessMode = '爬梯'
            } else if (database.AccessMode === 2) {
              form.AccessMode = '楼梯'
            } else if (database.AccessMode === 3) {
              form.AccessMode = '孔洞'
            } else if (database.AccessMode === 4) {
              form.AccessMode = '其他'
            }

            if (database.RidgeHeight !== '' && database.RidgeHeight !== null && database.RidgeHeight !== undefined && database.RidgeHeight !== 0) { // 屋脊高度
              form.RidgeHeight = database.RidgeHeight / 10 / 100 // 屋脊高度
            } else {
              form.RidgeHeight = ''
            }


            if (database.IsHaveDecorations === 0) { // 是否有装饰物
              form.IsHaveDecorations = '无'
            } else if (database.IsHaveDecorations === 1) {
              form.IsHaveDecorations = '有，不可拆除'
            } else if (database.IsHaveDecorations === 2) {
              form.IsHaveDecorations = '有，可拆除'
            }
          }else if (innerBuildingAgain !== null ){
            let database = JSON.parse(sessionStorage.getItem('innerBuildingAgain')).obliqueRoofBasicModel

            form.EastWestLength = parseInt(sessionStorage.getItem('inpValW')) / 10 / 100 // 东西长度
            form.NorthSouthWidth = parseInt(sessionStorage.getItem('inpValH1')) / 10 / 100 + sessionStorage.getItem('inpValH2') / 10 / 100 // 南北宽度
            form.SouthSlopeWidth = parseInt(sessionStorage.getItem('inpValH1')) / 10 / 100 // 南坡宽度
            form.SouthSlope = parseInt(database.southSlope) // 南坡坡度
            form.NorthSlopeWidth = parseInt(sessionStorage.getItem('inpValH2')) / 10 / 100 // 北坡宽度
            form.NorthSlope = parseInt(database.northSlope) // 北坡坡度
            form.EavesHigh = parseInt(database.eavesHigh) / 10 / 100 // 屋檐高度

            if(database.HouseHeight !==0 || database.HouseHeight !==null || database.HouseHeight !== undefined) { // 房屋高度
              form.HouseHeight = parseInt(database.houseHeight) / 10 / 100 // 房屋高度
            }else {
              form.HouseHeight = ''
            }

            // form.HouseHeight = parseInt(database.houseHeight) / 10 / 100 // 房屋高度
            if (database.houseFacing === 1) { // 房屋朝向
              form.HouseFacing = '正南'
            } else if (database.houseFacing === 2) {
              form.HouseFacing = '南偏东'
            } else if (database.houseFacing === 3) {
              form.HouseFacing = '南偏西'
            }

            if (database.facingDegree !== 0 && database.facingDegree !== null && database.facingDegree !== '' && database.facingDegree !== undefined) { // 朝向度数
              form.FacingDegree = database.facingDegree
            } else {
              form.FacingDegree = ''
            }

            form.GridConnectionDistance = parseInt(database.gridConnectionDistance) / 10 / 100 // 距并网点距离
            form.RidgeHeight = parseInt(database.ridgeHigh) / 10 / 100 // 屋脊高度

            if (database.roofingType === 1 ) { // 屋面类型
              form.RoofingType = '现浇板'
              form.RoofSlabThickness = parseInt(database.roofSlabThickness) / 10 / 100 // 屋面厚度
            } else if (database.roofingType === 2 ){
              form.RoofingType = '预制板'
              form.RoofSlabThickness = parseInt(database.roofSlabThickness) / 10 / 100 // 屋面厚度
            } else if (database.roofingType === 3) {
              form.RoofingType = '木望板'
              form.WoodPurlinDiameter = parseInt(database.woodPurlinDiameter) / 10 / 100  // 木檩直径
              form.WoodPurlinSpacing = parseInt(database.woodPurlinSpacing) / 10 / 100 // 木檩间距
              form.WoodWatchboardThickness = parseInt(database.woodWatchboardThickness) / 10 / 100 // 木望板厚度
            } else if (database.roofingType === 4) {
              form.RoofingType = '凛橼结构'
              form.PurlinDiameter = parseInt(database.purlinDiameter) / 10 / 100 // 木檩条直径
              form.PurlinSpacing = parseInt(database.purlinSpacing) / 10 / 100 // 木檩条间距
              form.HerringboneBeamEastWest = parseInt(database.herringboneBeamEastWest) / 10 / 100 // 人字梁东西跨度
              form.HerringboneBeamNorthSouth = parseInt(database.herringboneBeamNorthSouth)  / 10 / 100 // 人字梁南北跨度
              form.HerringboneGirderDiameter = parseInt(database.herringboneGirderDiameter) / 10 / 100 // 人字梁主梁直径
            }

            if (database.installationMethod === 1) {
              form.InstallationMethod = '前支后拉'
            } else if (database.installationMethod === 2) {
              form.InstallationMethod = '挂钩'
            }

          } else if (sessionStorage.getItem('requestInnerRoof') !== null) {  // 第一次回退基本信息

            let dataInfo = JSON.parse(sessionStorage.getItem('requestInnerRoof'))
            console.log(dataInfo)
            form.SouthSlope = parseInt(dataInfo.SouthSlope) // 南坡坡度
            form.NorthSlope = parseInt(dataInfo.NorthSlope) // 北坡坡度
            form.EavesHigh = parseInt(dataInfo.EavesHigh) / 10 / 100 // 屋檐高度
            form.HouseHeight = parseInt(dataInfo.HouseHeight) / 10 / 100 // 房屋高度
            if (dataInfo.HouseFacing === 1) { // 房屋朝向
              form.HouseFacing = '正南'
            } else if (dataInfo.HouseFacing === 2) {
              form.HouseFacing = '南偏东'
            } else if (dataInfo.HouseFacing === 3) {
              form.HouseFacing = '南偏西'
            }
            form.FacingDegree = parseInt(dataInfo.FacingDegree) // 朝向度数

            form.GridConnectionDistance = parseInt(dataInfo.GridConnectionDistance) / 10 / 100 // 距并网点距离
            form.RidgeHeight = parseInt(dataInfo.RidgeHeight) / 10 / 100 // 屋脊高度

            if (dataInfo.RoofingType === 1 ) { // 屋面类型
              form.RoofingType = '现浇板'
              form.RoofSlabThickness = parseInt(dataInfo.RoofSlabThickness) / 10 / 100 // 屋面厚度
            } else if (dataInfo.RoofingType === 2 ){
              form.RoofingType = '预制板'
              form.RoofSlabThickness = parseInt(dataInfo.RoofSlabThickness) / 10 / 100 // 屋面厚度
            } else if (dataInfo.RoofingType === 3) {
              form.RoofingType = '木望板'
              form.WoodPurlinDiameter = parseInt(dataInfo.WoodPurlinDiameter) / 10 / 100  // 木檩直径
              form.WoodPurlinSpacing = parseInt(dataInfo.WoodPurlinSpacing) / 10 / 100 // 木檩间距
              form.WoodWatchboardThickness = parseInt(dataInfo.WoodWatchboardThickness)  / 10 / 100 // 木望板厚度
            } else if (dataInfo.RoofingType === 4) {
              form.RoofingType = '凛橼结构'
              form.PurlinDiameter = parseInt(dataInfo.PurlinDiameter) / 10 / 100 // 木檩条直径
              form.PurlinSpacing = parseInt(dataInfo.PurlinSpacing) / 10 / 100 // 木檩条间距
              form.HerringboneBeamEastWest = parseInt(dataInfo.HerringboneBeamEastWest) / 10 / 100 // 人字梁东西跨度
              form.HerringboneBeamNorthSouth = parseInt(dataInfo.HerringboneBeamNorthSouth) / 10 / 100 // 人字梁南北跨度
              form.HerringboneGirderDiameter = parseInt(dataInfo.HerringboneGirderDiameter) / 10 / 100 // 人字梁主梁直径
            }

            if (dataInfo.InstallationMethod === 1) {
              form.InstallationMethod = '前支后拉'
            } else if (dataInfo.InstallationMethod === 2) {
              form.InstallationMethod = '挂钩'
            }

          }
        },{immediate: true, deep: true}
    )


    onMounted(() => {

      const qing = JSON.parse(sessionStorage.getItem('projectAddRequest'))
      // console.log(qing, '------------------')
      window.addEventListener('click', handleBodyClick)
      console.log(JSON.parse(sessionStorage.getItem('innerBuildingAgain')), '内厦屋顶基础数据')
      // if (sessionStorage.getItem('baseInfo') !== null) {
      //   let database = JSON.parse(sessionStorage.getItem('baseInfo'))
      //   data.form.EastWestLength = database.eastWestLength / 10 / 100 // 东西长度
      //   data.form.NorthSouthWidth = database.northSouthWidth / 10 / 100 // 南北宽度
      //   data.form.SouthSlopeWidth = database.southSlopeWidth / 10 / 100 // 南坡宽度
      //   data.form.SouthSlope = database.southSlope // 南坡坡度
      //   data.form.NorthSlopeWidth = database.northSlopeWidth / 10 / 100 // 北坡宽度
      //   data.form.NorthSlope = database.northSlope // 北坡坡度
      //   data.form.EavesHigh = database.eavesHigh / 10 / 100 // 屋檐高度
      //
      //
      // } else if (JSON.parse(sessionStorage.getItem('innerBuildingAgain'))) {
      //   let dataInner = JSON.parse(sessionStorage.getItem('innerBuildingAgain'))
      //   if (dataInner.obliqueRoofBasicModel !== null) {
      //     let dataInfo = dataInner.flatRoofBasicModel
      //     console.log(data, 99999)
      //     data.form.houseHeight = dataInfo.houseHeight / 10
      //     if (dataInfo.facingDirection === 1) {
      //       data.form.facingDirection = '南偏东'
      //     } else if (dataInfo.facingDirection === 2) {
      //       data.form.facingDirection = '南偏西'
      //     } else if (dataInfo.facingDirection === 3) {
      //       data.form.facingDirection = '正南'
      //     }
      //     data.form.facingDegree = dataInfo.facingDirection
      //     data.form.gridConnectionDistance = dataInfo.gridConnectionDistance / 10
      //   }
      // }
    })

    function handleBodyClick() {
      houseOrientationTypeShow.value = false
      roofTypeShow.value = false
      installationModeTypeShow.value = false
      AccessPositionTypeShow.value = false
      AccessModeTypeShow.value = false
      decorationShow.value = false
    }

    function choosePassageOrientationType(item) {
      form.AccessPosition = item
      passageLocationShow.value = false
    }

    function chooseHouseOrientationType(item) {
      form.HouseFacing = item
      houseOrientationTypeShow.value = false
    }
    function chooseRoofTypeShow(item) {
      form.RoofingType = item
      roofTypeShow.value = false
    }
    function chooseInstallationMode(item) {
      form.InstallationMethod = item
      installationModeTypeShow.value = false
    }
    function chooseAccessPosition(item) {
      form.AccessPosition = item
      AccessPositionTypeShow.value = false
    }
    function chooseAccessMode(item) {
      form.AccessMode = item
      AccessModeTypeShow.value = false
    }
    function chooseDecoration(item) {
      form.IsHaveDecorations = item
      decorationShow.value = false
    }
    function onSubmit() {
      const copyForm = JSON.parse(JSON.stringify(toRaw(form)))
      // copyForm.EastWestLength *= 10 * 100 // 东西长度
      // copyForm.NorthSouthWidth *= 10 * 100 // 南北宽度
      // copyForm.SouthSlopeWidth *= 10 * 100 // 南坡宽度
      // copyForm.NorthSlopeWidth *= 10 * 100 // 北坡宽度
      //
      // sessionStorage.setItem('inpValW', copyForm.EastWestLength)
      // sessionStorage.setItem('inpValH1', copyForm.NorthSlopeWidth)
      // sessionStorage.setItem('inpValH2', copyForm.SouthSlopeWidth)

      copyForm.EavesHigh *= 10 * 100 // 屋檐高度
      copyForm.HouseHeight *= 10 * 100 // 房屋高度
      if (copyForm.HouseFacing === '南偏东') { // 房屋朝向
        copyForm.HouseFacing = 1
      } else if (copyForm.HouseFacing === '南偏西') {
        copyForm.HouseFacing = 2
      } else if (copyForm.HouseFacing === '正南') {
        copyForm.HouseFacing = 3
        form.FacingDegree = 0
        copyForm.FacingDegree = 0
      }
      // if (copyForm.facingDirection === '南偏东') {
      //   copyForm.facingDirection = 1
      // } else if (copyForm.facingDirection === '南偏西') {
      //   copyForm.facingDirection = 2
      // } else {
      //   copyForm.facingDirection = 3
      //   data.form.facingDegree = 0
      //   copyForm.facingDegree = 0
      // }
      copyForm.GridConnectionDistance *= 10 * 100 // 距并网点距离

      if (copyForm.AccessPosition === '无') { // 上人通道位置
        copyForm.AccessPosition = 0
      } else if (copyForm.AccessPosition === '东侧') {
        copyForm.AccessPosition = 1
      } else if (copyForm.AccessPosition === '南侧') {
        copyForm.AccessPosition = 2
      } else if (copyForm.AccessPosition === '西侧') {
        copyForm.AccessPosition = 3
      } else if (copyForm.AccessPosition === '北侧') {
        copyForm.AccessPosition = 4
      }

      if (copyForm.AccessMode === '无') { // 上人通道方式
        copyForm.AccessMode = 0
      } else if (copyForm.AccessMode === '爬梯') {
        copyForm.AccessMode = 1
      } else if (copyForm.AccessMode === '楼梯') {
        copyForm.AccessMode = 2
      } else if (copyForm.AccessMode === '孔洞') {
        copyForm.AccessMode = 3
      } else if (copyForm.AccessMode === '其他') {
        copyForm.AccessMode = 4
      }

      copyForm.RidgeHeight *= 10 * 100 //屋脊高度

      if (copyForm.IsHaveDecorations === '无') { // 是否有装饰物
        copyForm.IsHaveDecorations = 0
      } else if (copyForm.IsHaveDecorations === '有，不可拆除') {
        copyForm.IsHaveDecorations = 1
      } else if (copyForm.IsHaveDecorations === '有，可拆除') {
        copyForm.IsHaveDecorations = 2
      }

      if (copyForm.RoofingType === '现浇板') { // 屋面类型
        copyForm.RoofingType = 1
        copyForm.RoofSlabThickness *= 10 * 100 // 屋面厚度
      } else if (copyForm.RoofingType === '预制板') {
        copyForm.RoofingType = 2
        copyForm.RoofSlabThickness *= 10 * 100 // 屋面厚度
      } else if (copyForm.RoofingType === '木望板') {
        copyForm.RoofingType = 3
        copyForm.WoodPurlinDiameter *= 10 * 100 // 木檩直径
        copyForm.WoodPurlinSpacing *= 10 * 100 // 木檩间距
        copyForm.WoodWatchboardThickness *= 10 * 100 // 木望板厚度
      } else if (copyForm.RoofingType === '檩橼结构') {
        copyForm.RoofingType = 4
        copyForm.PurlinDiameter *= 10 * 100 // 木檩条直径
        copyForm.PurlinSpacing *= 10 * 100 // 木檩条间距
        copyForm.HerringboneBeamEastWest *= 10 * 100 // 人字梁东西跨度
        copyForm.HerringboneBeamNorthSouth *= 10 * 100 // 人字梁南北跨度
        copyForm.HerringboneGirderDiameter *= 10 * 100 // 人字梁主梁直径
      }
      // if (copyForm.InstallationMethod === '混泥土基墩') { // 安装方式
      //   copyForm.InstallationMethod = 1
      // } else if (copyForm.InstallationMethod === '膨胀螺栓') {
      //   copyForm.InstallationMethod = 2
      // } else if (copyForm.InstallationMethod === '混凝土+膨胀螺栓') {
      //   copyForm.InstallationMethod = 3
      // }

      if (copyForm.InstallationMethod === '前支后拉') { // 安装方式
        copyForm.InstallationMethod = 1
      } else if (copyForm.InstallationMethod === '挂钩') {
        copyForm.InstallationMethod = 2
      }
      copyForm.isTrue = true // 加个变量用来识别 预览里是根据接口信息来解析，还是基本信息来解析
      copyForm.isEdit = true // 加个变量用来识别是否已经修改过

      sessionStorage.removeItem('baseInfo')
      sessionStorage.setItem('baseInfo', JSON.stringify(copyForm))
      sessionStorage.setItem('requestInnerRoof', JSON.stringify(copyForm))
      emit('popupClose', '基本信息')
    }

    function cancelEvent() {
      console.log(form, '取消')
      emit('update:baseInfoShow', false)
      emit('popupClose')
    }

    return {
      choosePassageOrientationType,
      form,
      houseOrientationTypeShow,
      passageLocationShow,
      roofTypeShow,
      installationModeTypeShow,
      AccessPositionTypeShow,
      AccessModeTypeShow,
      chooseHouseOrientationType,
      chooseRoofTypeShow,
      chooseInstallationMode,
      chooseAccessPosition,
      chooseAccessMode,
      onSubmit,
      cancelEvent,
      decorationShow,
      chooseDecoration
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 583px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 700;
      color: #323233;
      width: 94px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 66px;
    }
    .span2 {
      width: 122px;
    }
    .widthSpan{
      width: 144px;
    }
    .options {
      z-index: 1;
      width: 94px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 700;
      color: #1A1A1A;
      li {
        line-height: 20px;
      }
      li:active {
        color: #04cc66;
      }
    }
    .options1 {
      width: 66px;
      height: 108px;
    }
    .options2 {
      width: 122px;
    }
    .twoline {
      height: 48px;
    }
    .fourline {
      height: 88px;
    }
     .widthOptions {
      width: 144px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
