<template>
  <div class="box">
    <iframe
      ref="iframe"
      width="100%"
      height="80%"
      scrolling="no"
      frameborder="0"
      id="iframe"
    ></iframe>
    <div class="tabbox" :class="{ tabbox1:customer==='qingtian'}">
      <span
        :class="{ hoverAct: viewType == '俯视图' }"
        @click="data.viewType = '俯视图'"
      >俯视图</span
      >
      <span
        :class="{ hoverAct: viewType == '后视图' }"
        @click="viewType = '后视图'"
      >后视图</span
      >
    </div>
    <div class="btnbox" :class="{ btnbox1:customer==='qingtian'}">
      <div class="info" @click="infoEvent">基本信息</div>
      <div
        class="shelter"
        v-if="viewType == '俯视图'"
        @click.stop="shelterTypeShowEvent"
      >
        遮挡信息
      </div>
      <div
        class="door"
        v-if="viewType == '后视图'"
        @click.stop="shelterTypeShowEvent"
      >
        门窗信息
      </div>
      <ul
        class="options"
        v-show="shelterTypeShow && viewType == '俯视图'"
      >
        <li
          v-for="(item, index) in shelterInfoType"
          :key="index"
          @click="chooseShelterType(item)"
        >
          {{ item }}
        </li>
      </ul>
      <ul
        class="options options1"
        v-show="shelterTypeShow && viewType == '后视图'"
      >
        <li
          v-for="(item, index) in doorWindowType"
          :key="index"
          @click="chooseShelterType(item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <!-- eslint-disable vue/valid-v-model -->
    <van-popup v-model:show="show">
      <BaseInfo @popupClose="getPopupClose" />
    </van-popup>
    <van-popup v-model:show="shelterShow">
      <Parapet
        @popupClose="getPopupClose"
        v-show="shelterComShow == '女儿墙'"
      />
      <Chimney
        @popupClose="getPopupClose"
        v-show="shelterComShow == '烟囱'"
      />
      <Heater
        @popupClose="getPopupClose"
        v-show="shelterComShow == '热水器'"
      />
      <RoofObstacles
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋面障碍物'"
      />
      <OutdoorObstacles
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋外障碍物'"
      />
      <NeighborHouse
        @popupClose="getPopupClose"
        v-show="shelterComShow == '邻居房屋'"
      />
      <TelegraphPole
        @popupClose="getPopupClose"
        v-show="shelterComShow == '电线杆'"
      />
      <Wire
        @popupClose="getPopupClose"
        v-show="shelterComShow == '电线'"
      />
      <Tree @popupClose="getPopupClose" v-show="shelterComShow == '树'" />
      <BoardingPassage
        @popupClose="getPopupClose"
        v-show="shelterComShow == '上人通道'"
      />
      <Billboard
        @popupClose="getPopupClose"
        v-show="shelterComShow == '广告牌'"
      />
      <Door @popupClose="getPopupClose" v-show="shelterComShow == '门'" />
      <Window
        @popupClose="getPopupClose"
        v-show="shelterComShow == '窗'"
      />
    </van-popup>
  </div>
</template>

<script>
import { reactive, ref, onMounted, nextTick, toRefs } from 'vue'
import BaseInfo from './../../flatRoof/BaseInfo'
import Parapet from '@/components/obstacles/Parapet'
import Chimney from '@/components/obstacles/Chimney'
import Heater from '@/components/obstacles/Heater'
import RoofObstacles from '@/components/obstacles/RoofObstacles'
import OutdoorObstacles from '@/components/obstacles/OutdoorObstacles'
import NeighborHouse from '@/components/obstacles/NeighborHouse'
import TelegraphPole from '@/components/obstacles/TelegraphPole'
import Wire from '@/components/obstacles/Wire'
import Tree from '@/components/obstacles/Tree'
import BoardingPassage from '@/components/obstacles/BoardingPassage'
import Billboard from '@/components/obstacles/Billboard'
import Door from '@/components/obstacles/Door'
import Window from '@/components/obstacles/Window'
export default {
  components: {
    BaseInfo,
    Parapet,
    Chimney,
    Heater,
    RoofObstacles,
    OutdoorObstacles,
    NeighborHouse,
    TelegraphPole,
    Wire,
    Tree,
    BoardingPassage,
    Billboard,
    Door,
    Window
  },
  setup() {
    const iframe = ref(null)
    onMounted(() => {
      nextTick(() => {
        data.iframeWin = iframe.value.contentWindow
      })
    })
    const data = reactive({
      customer: window.customer,
      viewType: '俯视图',
      shelterTypeShow: false,
      shelterInfoType: [
        '女儿墙',
        '烟囱',
        '热水器',
        '屋面障碍物',
        '上人通道',
        '邻居房屋',
        '电线杆',
        '电线',
        '树',
        '广告牌',
        '屋外障碍物'
      ],
      doorWindowType: ['门', '窗'],
      show: false,
      shelterShow: false,
      shelterComShow: false,
      iframeWin: null
    })
    const methodsMap = {
      chooseShelterType(item) {
        data.shelterComShow = item
        data.shelterTypeShow = false
        data.shelterShow = true
      },
      infoEvent() {
        data.show = true
      },
      shelterTypeShowEvent() {
        data.shelterTypeShow = true
      },
      getPopupClose(value) {
        if (value && value instanceof Array) {
          data.shelterShow = false
        // sendMsgTo2D(toRaw(value))
        }
        if (value && value instanceof Array === false) {
          data.show = false
        // sendMsgTo2D('reset')
        }
        if (!value) {
          data.show = false
          data.shelterShow = false
        }
      }
    }

    return {
      ...toRefs(data),
      ...methodsMap,
      iframe,
    }
  }
}
</script>
<style scoped lang="scss">
.box {
  width: 375px;
  height: 400px;
  // background: #ffffff;
  position: relative;
  .tabbox {
    position: absolute;
    top: 5px;
    left: 16px;
    span {
      display: inline-block;
      width: 118px;
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      border: 1px solid #c7c9cc;
      font-size: 14px;
      font-weight: bold;
      color: #696c74;
      text-align: center;
      &:nth-child(1) {
        margin-right: 9px;
      }
    }
    .hoverAct {
      color: #04cc66;
      border: 1px solid #04cc66;
    }
  }
  .tabbox1 {
    top: 8px;
    span {
      display: inline-block;
      width: 66px;
      height: 28px;
      line-height: 28px;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #04cc66;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #04cc66;
      &:nth-child(1) {
        margin-right: 0;
      }
      &:nth-child(2) {
        border-radius: 0px 4px 4px 0px;
      }
    }
    .hoverAct {
      color: #ffffff;
      background: #04cc66;
    }
  }
  .btnbox {
    position: absolute;
    top: 5px;
    right: 16px;
    .info,
    .shelter,
    .door {
      width: 86px;
      height: 28px;
      background: #04cc66;
      border-radius: 4px 4px 4px 4px;
      line-height: 28px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
    .info {
      margin-bottom: 8px;
    }
    .options {
      width: 86px;
      height: 296px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #04cc66;
      text-align: center;
      color: #696c74;
      font-size: 14px;
      padding: 0 4px;
      padding: 6px;
      li {
        border-bottom: 1px solid rgba(199, 201, 204, 0.5);
        line-height: 25px;
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
    .options1 {
      height: 62px;
    }
  }
  .btnbox1{
    top: -26px;
  }

}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 80px;
}
</style>
